import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// pages
import Landing from './modules/Index';

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Landing} />
      </Router>
    </div>
  );
}

export default App;
