import * as React from "react";
import logo from "../assets/images/logo_light.svg";

class C extends React.PureComponent{
  render(){
    return (
      <>
        <div id="gradient-bg"></div>
        <header id="header" className="header scrolled">
          <div className="header-container container">
            <a href="/" className="item-logo">
              <img src={logo} alt="vergent" />
            </a>
          </div>
        </header>
      </>
    )
  }
}

export const NavHeader = C;