import * as React from 'react'

import { NavHeader } from './Header';
// import Footer from "./Footer";

function Template( {page, children}: any ) {
  return (
    <React.Fragment>
      <div className="site-container">
        <NavHeader />

        <main className="page-container page-container--no-demo">
          {children}
        </main>
          
        {/* <Footer />   */}
      </div>   
        
    </React.Fragment>
  )
}

export default Template

