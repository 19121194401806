import React from "react";
import Template from "../components/Template";


function Landing() {

  return (
    <Template page={1}>
      <div id="page-home">
        <section className="home-banner">
          <div className="home-banner-main">
            <div className="flex home-banner-container py-lg">
              <div className="row jc-center">
                <div className="col-lg-7">
                  <h1 className="title-xl">Inspiring growth through disruptive innovation.</h1>

                  <div className="cms container pt-md pb-lg">
                    <span className="TextRun SCXW223193348 BCX0" data-contrast="auto">
                      <span className="NormalTextRun SCXW223193348 BCX0">
                          Vergent Technologies enable organizations optimize their processes, improve efficiency, 
                          make data-driven decisions and leverage advanced technologies to stay competitive in the 
                          modern digital landscape.
                      </span>
                    </span>
                  </div>

                  <a href="/" className="button button--c-blue button--bg-white">
                    <div className="button-title">
                      Discover Vergent
                    </div>

                    <div className="button-icon">
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <svg className="waves my-xl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                  <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                  <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                  <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                  <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                  <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
              </svg>
            </div>
          </div>
        </section>
      </div>
    </Template>
  );
}

export default Landing;
